<template>
    <div>
        <div class="settings-button" @click="toggleSettingsPopup">
            <span class="material-icons notranslate" style="color: white">settings</span>
        </div>

        <div class="share-button" @click="toggleSharePopup">
            <span class="material-icons notranslate" style="color: white">share</span>
        </div>

        <div class="links-button" @click="toggleLinksPopup">
            <span class="material-icons notranslate" style="color: white">link</span>
        </div>

        <div class="overlay" v-if="settingsPopupDisplayed && !this.showSettingsOnWideScreen()" @click="toggleSettingsPopup"></div>
        <div class="overlay" v-if="sharePopupDisplayed" @click="toggleSharePopup"></div>
        <div class="overlay" v-if="linksPopupDisplayed" @click="toggleLinksPopup"></div>

        <div class="settings-popup" :class="showSettingsOnWideScreen() ? 'settings-popup-wide-screen' : 'settings-popup-small-screen'
"                v-if="settingsPopupDisplayed">

            <!--        TODO put to grid-->
            <!--        TODO the text display conditions are actually inverted, although the solution works its wrong ...-->
            <b-form-checkbox size="sm" id="forecastDisplayMode" v-model="centerOnMidnight" name="checkbox-1" value="true" unchecked-value="false" button button-variant="warning">
                <span class="material-icons notranslate" style="font-size: 15px; margin-right: 7px; margin-bottom: 2px">adjust</span>
                <span>{{ this.shouldCenterOnMidnight ? 'Vycentrovat poledne' : 'Vycentrovat půlnoc' }}</span>
            </b-form-checkbox>
            <b-form-checkbox v-if="!isForeignLocation" size="sm" id="forecastSortMode" v-model="sortByReliability" name="checkbox-1" value="true" unchecked-value="false" button button-variant="warning"
                             style="margin-top: 15px; width: 226px">
                <!--                fixed width needed for resizing-->
                <span class="material-icons notranslate" style="font-size: 15px; margin-right: 7px; margin-bottom: 2px">sort</span>
                <span>{{ this.shouldSortByReliability ? 'Řadit předpovědi abecedně' : 'Řadit předpovědi dle přesnosti'}}</span>
            </b-form-checkbox>

            <div style="margin-top: 15px">
                <b-dropdown size="sm" text="Ø Předpovědí oblačnosti" right variant="warning">
                    <b-dropdown-item-button :active="forecastAvgMode === 'ALL'" v-on:click="updateForecastAvgMode('ALL')">Všech zobrazených</b-dropdown-item-button>
                    <b-dropdown-item-button :active="forecastAvgMode === 'BEST_3'" v-on:click="updateForecastAvgMode('BEST_3')">3 nejpřesnějších</b-dropdown-item-button>
                    <b-dropdown-item-button :active="forecastAvgMode === 'BEST_4'" v-on:click="updateForecastAvgMode('BEST_4')">4 nejpřesnějších</b-dropdown-item-button>
                    <b-dropdown-item-button :active="forecastAvgMode === 'BEST_5'" v-on:click="updateForecastAvgMode('BEST_5')">5 nejpřesnějších</b-dropdown-item-button>
                    <b-dropdown-item-button :active="forecastAvgMode === 'NONE'" v-on:click="updateForecastAvgMode('NONE')">Nezobrazovat</b-dropdown-item-button>
                </b-dropdown>
            </div>

            <DetailPreferencesSettingsPopup class="popup-item"
                                            @childPopupClosed="onChildPopupClosed">
            </DetailPreferencesSettingsPopup>

            <DisplayedSourcesSettingsPopup class="popup-item"
                                           @childPopupClosed="onChildPopupClosed">
            </DisplayedSourcesSettingsPopup>

            <ForecastSourceReliabilityIndicatorSettingsPopup v-if="!isForeignLocation" class="popup-item"
                                                             @childPopupClosed="onChildPopupClosed">
            </ForecastSourceReliabilityIndicatorSettingsPopup>

            <WiderRegionSettingsPopup v-if="!isForeignLocation" class="popup-item"
                                      @childPopupClosed="onChildPopupClosed">
            </WiderRegionSettingsPopup>

            <NightSettingsPopup class="popup-item"
                                @childPopupClosed="onChildPopupClosed">
            </NightSettingsPopup>

            <div>
                <b-button v-if="!isDisplayedWholeCountry" size="sm" :variant="toggleHomeLocationButtonVariant()"
                          style="margin-top: 15px; text-align: left; white-space: nowrap;" @click="toggleHomeLocation">
                    <!--                fixed width needed for resizing-->
                    <span class="material-icons notranslate" style="font-size: 15px; margin-right: 7px; margin-bottom: 2px">home</span>
                    <span>{{ this.homeLocationDisplayed ? 'Smazat domovskou polohu' : 'Uložit polohu jako domovskou'}}</span>
                </b-button>
            </div>

        </div>

        <SharePopup v-if="sharePopupDisplayed"></SharePopup>
        <LinksPopup v-if="linksPopupDisplayed" @close-popup="toggleLinksPopup"></LinksPopup>
    </div>
</template>



<script>
import {mapActions, mapGetters} from "vuex";
import {Utils} from "@/utils/Utils";
import NightSettingsPopup from "@/components/forecast/settings/NightSettingsPopup.vue";
import {BButton, BDropdown, BDropdownItemButton, BFormCheckbox} from "bootstrap-vue";
import WiderRegionSettingsPopup from "@/components/forecast/settings/WiderRegionSettingsPopup.vue";
import ForecastSourceReliabilityIndicatorSettingsPopup
    from "@/components/forecast/settings/ForecastSourceReliabilityIndicatorSettingsPopup.vue";
import DisplayedSourcesSettingsPopup from "@/components/forecast/settings/DisplayedSourcesSettingsPopup.vue";
import SharePopup from "@/components/forecast/SharePopup.vue";
import LinksPopup from "@/components/forecast/LinksPopup.vue";
import DetailPreferencesSettingsPopup from "@/components/forecast/settings/DetailPreferencesSettingsPopup.vue";

export default {
    name: "Popups",
    props: {
        hour: null
    },
    mounted() {
        this.centerOnMidnight = this.shouldCenterOnMidnight;
        // console.log("this.centerOnMidnight = " + this.centerOnMidnight)
        this.sortByReliability = this.shouldSortByReliability;
        this.forecastAvgMode = this.getForecastAvgMode;
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    data() {
        return {
            centerOnMidnight: undefined, // gets populated with text boolean actually! causes a lot of mess if not properly taken care of ...
            sortByReliability: undefined, // gets populated with text boolean actually! causes a lot of mess if not properly taken care of ...
            forecastAvgMode: 'ALL',

            settingsPopupDisplayed: this.showSettingsOnWideScreen(),
            sharePopupDisplayed: false,
            linksPopupDisplayed: false,
            wideScreen: Utils.checkWideScreen()
        }
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        getForecastsLink: function (newValue) {
            // console.log("Inside watch for getForecastsLink ...")
            this.sharePopupDisplayed = true;
        },
        centerOnMidnight: function (newValue, oldValue) {
            if (oldValue !== undefined) {
                this.toggleCenterOnMidnight(newValue === 'true' || newValue === true);
            }
        },
        sortByReliability: function (newValue, oldValue) {
            if (oldValue !== undefined) {
                this.toggleSortByReliability(newValue === 'true' || newValue === true);
            }
        },
        forecastAvgMode: function (newValue, oldValue) {
            if (oldValue !== undefined) {
                this.changeForecastAvgMode(newValue);
            }
        },
    },
    computed: {
        Utils() {
            return Utils
        },
        ...mapGetters('forecastsStore', [
            'isDisplayedWholeCountry', 'isForeignLocation',
            'shouldCenterOnMidnight', 'shouldSortByReliability', 'getForecastAvgMode',
            'homeLocationDisplayed', 'getForecastsLink'
        ]),
    },
    methods: {
        ...mapActions('forecastsStore', [
            'changeForecastAvgMode', 'toggleCenterOnMidnight', 'toggleSortByReliability',
            'fetchForecastsLink', 'saveHomeLocation', 'deleteHomeLocation'
        ]),

        toggleSettingsPopup() {
            this.settingsPopupDisplayed = !this.settingsPopupDisplayed;
            this.hideSharePopup();
            this.hideLinksPopup();
        },
        hideSettingsPopup() {
            this.settingsPopupDisplayed = false;
        },
        hideSettingsPopupOnSmallScreens() {
            if (!this.showSettingsOnWideScreen()) {
                this.hideSettingsPopup();
            }
        },
        toggleSharePopup() {
            if (this.sharePopupDisplayed) {
                this.sharePopupDisplayed = false;
            } else {
                this.fetchForecastsLink();
            }
            if (!this.showSettingsOnWideScreen()) {
                this.hideSettingsPopup();
            }
            this.hideLinksPopup();
        },
        hideSharePopup() {
            this.sharePopupDisplayed = false;
        },
        toggleLinksPopup() {
            this.linksPopupDisplayed = !this.linksPopupDisplayed;
            this.hideSharePopup();
            if (!this.showSettingsOnWideScreen()) {
                this.hideSettingsPopup();
            }
        },
        hideLinksPopup() {
            this.linksPopupDisplayed = false;
        },
        onChildPopupClosed() {
            this.hideSettingsPopupOnSmallScreens();
        },
        showSettingsOnWideScreen() {
            return window.innerWidth > 1500;
        },
        handleResize() {
            this.wideScreen = Utils.checkWideScreen();
        },
        toggleHomeLocation() {
            if (this.homeLocationDisplayed) {
                this.deleteHomeLocation();
            } else {
                this.saveHomeLocation();
            }
        },
        toggleHomeLocationButtonVariant() {
            if (this.homeLocationDisplayed) {
                return "danger";
            } else {
                return "success";
            }
        },

        updateForecastAvgMode(mode) {
            // TODO update in the store ...
            this.forecastAvgMode = mode;
        },
    },
    components: {
        DetailPreferencesSettingsPopup,
        BDropdown,
        LinksPopup,
        SharePopup,
        DisplayedSourcesSettingsPopup,
        ForecastSourceReliabilityIndicatorSettingsPopup,
        BButton,
        WiderRegionSettingsPopup,
        BDropdownItemButton,
        BFormCheckbox,
        NightSettingsPopup
    }
}
</script>




<style scoped>

.settings-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #fea900;
    padding: 10px;
    border-radius: 20%;
    cursor: pointer;
    z-index: 1000;
}

.settings-popup {
    position: fixed;
    bottom: 85px;
    right: 20px;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    z-index: 10000;
}

.settings-popup-small-screen {
    background-color: rgba(245, 245, 245);
    border: 1px solid #ccc;
}

.settings-popup-wide-screen {
    background-color: rgba(245, 245, 245, 0.5);
}

.share-button {
    position: fixed;
    bottom: 20px;
    right: 76px;
    background-color: #fea900;
    padding: 10px;
    border-radius: 20%;
    cursor: pointer;
    z-index: 1000;
}

.links-button {
    position: fixed;
    bottom: 20px;
    right: 132px;
    background-color: #fea900;
    padding: 10px;
    border-radius: 20%;
    cursor: pointer;
    z-index: 1000;
}

.popup-item {
    margin-top: 15px;
}


</style>
