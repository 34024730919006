<template>
    <div>
        <div style="min-width: 300px; width: 500px">
            <b-input-group>
                <b-input-group-prepend>
                    <b-input-group-text style="background-color: #f5f5f5">
                        <i class="bi bi-search" style="color: #086cd2;"></i>
                    </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                        ref="locationInput"
                        v-model="searchText"
                        @input="hideLocations"
                        @click="insideInput"
                        placeholder="Vyhledávání polohy"
                        autocomplete="off"
                />
                <b-input-group-append>
                    <b-form-select style="width: 87px; background-color: #f5f5f5"
                            v-model="selectedCountryCode"
                            @change="hideLocations"
                            :options="countryOptions"
                            class="outline-secondary no-left-radius">
                    </b-form-select>
                </b-input-group-append>
            </b-input-group>
            <div class="my-list-group" :style="getWidthStyle()">
                <b-list-group v-if="locations && locations.length > 0" ref="locationList">
                    <b-list-group-item button v-for="location in locations" :key="location._idx"
                                       @click="selectLocation(location)"
                                       variant="light" style="color: #464646">
<!--                        each span must be on one line as otherwise it adds extra space-->
                        <span style="font-weight: 600" class="notranslate">{{location.displayNameMain}}</span>
                        <span v-if="location.displayNameRest" class="notranslate">{{", " + location.displayNameRest}}</span>
                        <span style="float: right; padding-right: 0px">
<!--                            padding are important for giving space to click the small icon-->
                            <a :href="getMapyCzLink(location)" @click.stop target="_blank" class="link rounded" style="padding-left: 10px; padding-top: 10px; padding-bottom: 10px" rel="noreferrer">
                                <span class="material-icons link-icon notranslate" style="font-size: 18px; margin-bottom: 3px">
                                    location_on
                                </span>
                            </a>
                            <span v-if="location.saved" @click.stop="removeLocation(location)" style="padding-left: 10px; padding-right: 0px">
                                <i class="fa-xs bi bi-trash-fill" style="color: #d25858"></i>
                            </span>
                        </span>

                    </b-list-group-item>
                    <!-- Add the logo list item if there are more than one item -->
                    <b-list-group-item v-if="locations && locations.length > 0"
                    style="text-align: right; padding: 2px">
                        <span style="color: #8c8c8c; font-size: 11px;">Powered by</span>
                        <img src="https://api.mapy.cz/img/api/logo.svg" alt="Mapy.cz logo" style="height: 17px;">
                    </b-list-group-item>
                </b-list-group>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {ApiClient} from "@/api/calculations/api-client";
import {
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroupText,
    BListGroup,
    BListGroupItem
} from "bootstrap-vue";
import {Utils} from "@/utils/Utils";

export default {
    name: "LocationAutocomplete",
    components: {
        BInputGroup,
        BFormInput,
        BListGroup,
        BListGroupItem,
        BInputGroupText,
        BInputGroupPrepend,
        // eslint-disable-next-line vue/no-unused-components
        BDropdown,
        // eslint-disable-next-line vue/no-unused-components
        BDropdownItem,
        BInputGroupAppend,
        BFormSelect
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside)
    },
    data() {
        return {
            selectedCountryCode: 'CZ',
            searchText: '',
            locations: [],
            timer: null,
            clearedSearchText: false,
            countryOptions: [
                { value: "CZ", text: "Česko" },
                { value: "BA", text: "Bosna and Hercegovina", visible: true },
                { value: "BG", text: "Bulharsko", visible: true },
                { value: "ME", text: "Černá Hora", visible: true },
                { value: "FI", text: "Finsko", visible: true },
                { value: "FR", text: "Francie", visible: true },
                { value: "HR", text: "Chorvatsko", visible: true },
                { value: "IS", text: "Island", visible: true },
                { value: "IT", text: "Itálie", visible: true },
                { value: "HU", text: "Maďarsko", visible: true },
                { value: "DE", text: "Německo" },
                { value: "NO", text: "Norsko", visible: true },
                { value: "PL", text: "Polsko" },
                { value: "PT", text: "Portugalsko", visible: true },
                { value: "AT", text: "Rakousko" },
                { value: "RO", text: "Rumunsko", visible: true },
                { value: "SI", text: "Slovinsko", visible: true },
                { value: "SK", text: "Slovensko" },
                { value: "ES", text: "Španělsko", visible: true },
                { value: "SE", text: "Švědsko", visible: true },
            ].filter(option => this.isMe() || !option.visible), // Filtered based on visibility
        };
    },
    watch: {
        searchText(newVal) {
            if (this.clearedSearchText) {
                this.clearedSearchText = false;
                // console.log("Skipping - cleared search text")
                return;
            }
            // console.log("Inside watch for searchText")
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                if (newVal.length >= 3) {
                    this.lookupLocations(newVal);
                } else if (newVal.length === 0 && !this.isLoading) {
                    this.showStoredLocations();
                } else {
                    this.hideLocations();
                }
            }, 300);
        }
    },
    computed: {
        ...mapGetters('forecastsStore', ['getLatitude', 'getLongitude', 'isLoading'])
    },
    methods: {
        ...mapActions('forecastsStore', ['loadForecasts']),
        handleClickOutside(event) {
            const locationList = this.$refs.locationList;
            const locationInput = this.$refs.locationInput;
            if ((locationInput && locationInput.$el !== event.target) && (locationList && !locationList.contains(event.target))) {
                this.hideLocations();
            }
        },
        hideLocations() {
            console.log("INside hide locations")
            this.locations = [];
        },
        lookupLocations(searchText) {
            ApiClient.fetchAutocompletedLocations(searchText, this.selectedCountryCode.toLowerCase())
                .then(locs => {
                    this.locations = locs.locations;
                })
        },
        insideInput() {
            if (this.searchText) {
                this.lookupLocations(this.searchText);
            } else {
                this.showStoredLocations();
            }
        },
        showStoredLocations() {
            const storedForecastLocations = this.loadStoredLocationsForSelectedCountry();
            if (storedForecastLocations) {
                this.locations = storedForecastLocations;
            }
        },
        removeLocation(location) {
            const storedForecastLocations = this.loadAllStoredLocations();
            if (storedForecastLocations) {
                const otherLocations = storedForecastLocations.filter(l => l.lat !== location.lat && l.lon !== location.lon);
                localStorage.setItem('storedForecastLocations', JSON.stringify(otherLocations))
                this.locations = this.loadStoredLocationsForSelectedCountry();
            }
        },
        // eslint-disable-next-line no-unused-vars
        selectLocation(location) {
            this.locations = [];
            const storedForecastLocations = this.loadAllStoredLocations();
            let updatedLocations;
            if (storedForecastLocations) {
                const maxCount = 10;

                const otherLocationsSameCountry = storedForecastLocations
                    .filter(l => l.lat !== location.lat && l.lon !== location.lon
                    && (l.countryCode === this.selectedCountryCode.toLowerCase()
                            || !l.countryCode && this.selectedCountryCode === 'CZ')
                    );

                const otherLocationsDifferentCountry = storedForecastLocations
                    .filter(l => l.lat !== location.lat && l.lon !== location.lon
                        && (l.countryCode !== this.selectedCountryCode.toLowerCase()
                            || !l.countryCode && this.selectedCountryCode !== 'CZ')
                    );

                updatedLocations = [location, ...otherLocationsSameCountry.slice(0, maxCount - 1), ...otherLocationsDifferentCountry];
            } else {
                updatedLocations = [location];
            }
            localStorage.setItem('storedForecastLocations', JSON.stringify(updatedLocations))
            this.loadForecasts(location);
            this.searchText = "";
            this.clearedSearchText = true;
        },
        loadStoredLocationsForSelectedCountry() {
            const storedForecastLocations = localStorage.getItem('storedForecastLocations');
            if (storedForecastLocations) {
                return JSON.parse(storedForecastLocations).map(l => ({ ...l, saved: true })).filter(
                    l => l.countryCode === this.selectedCountryCode.toLowerCase() || (!l.countryCode && this.selectedCountryCode.toLowerCase() === "cz")
                );
            }
            return null;
        },
        loadAllStoredLocations() {
            const storedForecastLocations = localStorage.getItem('storedForecastLocations');
            if (storedForecastLocations) {
                return JSON.parse(storedForecastLocations).map(l => ({ ...l, saved: true }));
            }
            return null;
        },
        getWidthStyle() {
            const screenWidth = document.documentElement.scrollWidth;
            let width;
            if (screenWidth < 300) {
                width = 250;
            } else if (screenWidth < 350) {
                width = 270;
            } else if (screenWidth < 400) {
                width = 320;
            } else if (screenWidth < 500) {
                width = 370;
            } else {
                width = 400;
            }
            return `width: ${width}px`;
        },
        getMapyCzLink(location) {
            if (location.lat && location.lon) {
                // https://mapy.cz/turisticka?x=14.4418032&y=50.0554423&z=8
                return `https://mapy.cz/turisticka?x=${location.lon}&y=${location.lat}&z=14`;
            } else {
                return null;
            }
        },
        isMe() {
            return Utils.isMe();
        },
    }
};
</script>

<style scoped>
.my-list-group {
    position: absolute;
    z-index: 1000; /* Set a high z-index value to make it overlap other content */
    overflow-y: auto;
    max-height: 400px;
    /*min-width: 250px;*/
    /*max-width: 380px*/
}
.no-left-radius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
/*needed to remove blue box shadow when the country select is clicked and has focus */
.custom-select:focus {
    border-color: #ced4da;
    outline: 0;
    box-shadow: none;
}
</style>
