
const state = {
    currentHour: new Date().getHours(),
}


const getters = {

    getCurrentHour: (state) => {
        return state.currentHour;
    },

    getTableCenteringClass: (state, getters, rootState, rootGetters) => {
        const centeredOnMidnight = rootGetters["forecastsStore/shouldCenterOnMidnight"];
        return centeredOnMidnight ? 'night-centered' : 'day-centered';
    },

    currentHourStyle: (state, getters, rootState, rootGetters) => (hour) => {
        const centeredOnMidnight = rootGetters["forecastsStore/shouldCenterOnMidnight"];
        const isDisplayedToday = rootGetters["forecastsStore/isDisplayedToday"];
        if (isDisplayedToday && (!centeredOnMidnight || (centeredOnMidnight && hour >= 12))) {
            let current = getters.getCurrentHour === hour;
            if (current) {
                const currentHourColor = "#fffddf";
                return `background-color: ${currentHourColor}; text-align: center; color: black;`;
            }
        }
        return "text-align: center; ";
    },

}


const mutations = {

    updateCurrentHour(state) {
        state.currentHour = new Date().getHours();
    },

}

const actions = {

    startCurrentHourUpdate({ commit }) {
        setInterval(() => {
            commit("updateCurrentHour"); // Mutates the state every second
        }, 1000);
    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}


