<template>
    <div>
        <b-button size="sm" @click="togglePopup" variant="secondary">
            <SettingsIcon name="settings"></SettingsIcon>
            Zobrazení modelů
        </b-button>

        <div class="overlay" v-if="popupDisplayed" @click="togglePopup"></div>

        <Transition>
            <div v-if="popupDisplayed" class="popup">
                <div class="ml-auto mr-3" v-on:click="togglePopup">
                    <span class="popup-close">&times;</span>
                </div>
                <div style="text-align: center; margin-bottom: 10px">
                    <h4 class="popup-title">Jaké modely zobrazovat?</h4>
                    <hr/>
                    <div class="row">
                        <p class="popup-text">
                            Kromě samotného zobrazení modelů tady určíte, které modely budou vstupovat do různých agregací
                             (např. mapa vývoje oblačnosti v regionech, souhrnná ikonka pro volbu data předpovědí ...)
                        </p>
                    </div>

                    <div class="row" style="text-align: left">
                        <div style="margin-left: 30px">
                            <b-form-checkbox v-for="sourceCode in getSourceCodes" :key="sourceCode" v-model="displayedSources"
                                             :value="sourceCode" style="margin-bottom: 6px; font-size: medium"
                                             @change="touchedSettings">
                                {{ getDisplayName(sourceCode) }}
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>
                <div style="text-align: center; margin-bottom: 12px">
                    <b-button @click="clickOk" variant="primary" >
                        Nastavit
                    </b-button>
                </div>
            </div>
        </Transition>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {BButton, BFormCheckbox} from "bootstrap-vue";
import SettingsIcon from "@/components/forecast/SettingsIcon.vue";
import {Sources} from "@/config/sources";

export default {
    name: "DisplayedSourcesSettingsPopup",
    computed: {
        ...mapGetters('settingsStore', ['getDisplayedSources', 'getForbiddenSources', 'getSourceCodes'])
    },
    mounted() {
        this.displayedSources = this.getDisplayedSources.map(s => s); // copy
    },
    watch: {
    },
    data() {
        return {
            popupDisplayed: false,
            displayedSources: [],
            initialised: false
        }
    },
    methods: {
        ...mapActions('settingsStore', ['hideSource', 'unhideSource']),
        ...mapActions('forecastsStore', ['reloadForecasts']),
        togglePopup() {
            this.popupDisplayed = !this.popupDisplayed;
            if (this.popupDisplayed) {
                this.$emit('childPopupOpened');
            } else {
                this.$emit('childPopupClosed');
            }
        },
        getDisplayName(sourceCode) {
            return Sources.displayName(sourceCode);
        },
        touchedSettings() {
            this.shouldReloadForecasts = true;
        },
        clickOk() {
            this.togglePopup();

            this.getSourceCodes.forEach(sourceCode => {
                if (!this.displayedSources.includes(sourceCode)) {
                    this.hideSource({ sourceCode: sourceCode, reload: false });
                } else {
                    this.unhideSource({ sourceCode: sourceCode, reload: false });
                }
            });

            if (this.shouldReloadForecasts) {
                this.reloadForecasts();
            }
            this.shouldReloadForecasts = false;
        },
    },
    components: {
        SettingsIcon,
        BFormCheckbox,
        BButton,
    }
}
</script>

<style scoped>

</style>
