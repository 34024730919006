<template>
    <span class="material-icons notranslate" style="font-size: 15px; margin-right: 7px; margin-bottom: 2px">{{name}}</span>
</template>

<script>
import {utilsMixin} from "@/components/reusables/utils-mixin";

export default {
    name: 'SettingsIcon',
    mixins: [utilsMixin],
    props: {
        name: String,
    },
}
</script>

<style scoped>


</style>
