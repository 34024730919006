<template>
    <div>
    </div>
</template>


<script>

export default {
    name: 'SetFavicon',
    mounted() {
        const link = document.createElement('link');
        link.rel = 'icon';
        link.type = 'image/png';
        link.sizes = '48x48';
        link.href = require('@/assets/site_logo/logo-cloud-telescope-v4.png');
        document.head.appendChild(link);
    }
}

</script>

<style>

/*@import './assets/styles/calc.css';*/

</style>

<style lang="scss">

//@import 'node_modules/bootstrap/scss/bootstrap.scss';
//@import 'node_modules/bootstrap-vue/src/index.scss';


</style>
