import Vue from 'vue';
import App from './components/App';
import store from './store/index';
import {router} from './router/index';

// IMPORTANT: this is too buig for the build
// import {BootstrapVue} from 'bootstrap-vue'

// IMPORTANT These cause duplications when we also use bootstrap for sass
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// https://bootstrap-vue.org/docs
import './assets/styles/scss/main.scss'
import VueLoading from 'vue-loading-template'


// Install BootstrapVue
// more info here: https://bootstrap-vue.org/docs
// Vue.use(BootstrapVue)

Vue.use(VueLoading, /** options **/)

require('./assets/styles/calc.css');
require('./assets/styles/forecast-table-styles.css');
require('./assets/styles/font-styles.css');
require('./assets/styles/material-icons.css');


new Vue({
    router,
    store,
    render: h => h(App),
    created() {
        this.$store.dispatch("forecastStylesStore/startCurrentHourUpdate");
    },
}).$mount('#app');
