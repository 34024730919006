<template>

    <div class="d-flex justify-content-center align-items-center"
         style="margin-bottom: 20px">
        <div v-for="daily in getDailyOverall" :key="daily.day" class="day-card day-card-size notranslate"
             @click="changeDaysOffsetFromToday(daily.day - 1)"
             :style="getDayCardStyle(daily.day)">
            {{ getDisplayedDate(daily.date) }}
            <img v-if="daily.meteoblueIcon" class="day-icon"
                 :src="require('@/assets/icons/meteoblue/weather_pictograms/png/' + daily.meteoblueIcon + '_night.png')">
        </div>
    </div>
</template>

<script>

// Video on  https://www.youtube.com/watch?v=N8-p9bdYWqs&ab_channel=ChartJS

import {mapActions, mapGetters} from "vuex";
import {BFormGroup, BFormRadio, BFormRadioGroup, BIcon} from 'bootstrap-vue';
import {Utils} from "@/utils/Utils";

export default {
    name: "ForecastDays",
    computed: {
        ...mapGetters('forecastsStore', ['getDailyOverall', 'getDaysOffsetFromToday']),
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        getForecasts: function (newValue) {
            console.log("Inside watch for getForecasts ...")
            if (this.scrolling != null) {
                // console.log("About to scroll ...")
                this.scrolling();
            } else {
                // first time we are getting the forecast
                this.scrollTable();
            }
            this.enabledDates = true;
            this.scrolling = null;
        },
    },
    data() {
        return {
            borderShadowSelected: 'box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.4)',
            borderShadowNotSelected: 'box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.13)',
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['changeDaysOffsetFromToday']),
        getDisplayedDate(dateStr) {
            // eslint-disable-next-line no-unused-vars
            const date = new Date(dateStr);
            return Utils.formatDate(date, false, true, true);
        },
        getDayCardStyle(day) {
            const selected = this.getDaysOffsetFromToday + 1 === day;
            if (selected) {
                const background = 'background-color: #086cd2';
                // const fontWeight = 'font-weight: bold';
                const fontWeight = '';
                return `${background};${this.borderShadowSelected};${fontWeight};color:white`;
            } else {
                return this.borderShadowNotSelected;
            }
        }
    },
    components: {
        // eslint-disable-next-line vue/no-unused-components
        BFormGroup, BFormRadio, BFormRadioGroup, BIcon
    }
}
</script>

<style scoped>

.day-card {
    border: 1px solid #cccccc;
    border-radius: 5px 5px 5px 5px;
    background-color: rgb(245, 245, 245);
    text-align: center;
    cursor: pointer;
    padding-top: 2px;
    /*color: #086cd2;*/
}

@media (max-width: 500px) {
    .day-card-size {
        width: 37px;
        margin: 4px;
        font-size: 11px;
    }

    .day-icon {
        width: 35px;
        height: 35px;
        border-radius: 0 0 5px 5px;
    }

    .day-card-selected-size {
        width: 32px;
        margin: 4px;
        font-size: 11px;
    }

    .day-icon-selected {
        width: 39px;
        height: 39px;
        border-radius: 0 0 5px 5px;
    }
}

@media (min-width: 500px) {
    .day-card-size {
        width: 44px;
        margin: 9px;
        font-size: 13px;
    }

    .day-icon {
        width: 42px;
        height: 42px;
        border-radius: 0 0 5px 5px;
    }
}


</style>
