import Big from "big.js";

export class Utils {

    static isMe() {
        const usesPlausible = localStorage.getItem('plausible_ignore');
        if (usesPlausible === 'true') {
            return true;
        } else {
            return false;
        }
    }

    static getScreenWidth() {
        if (window.innerWidth <= 500) {
            return "small";
        } else if (window.innerWidth <= 700) {
            return "medium";
        } else {
            return "large";
        }
    }

    static todayDate() {
        const dateStr = this.toDate_yyyyMMdd(new Date());
        return new Date(dateStr);
    }

    static toDate_yyyyMMdd(date) {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = (date.getDate()).toString().padStart(2, '0');
        return [date.getFullYear(), month, day].join('-');
    }

    static toDate_Md(date) {
        const month = (date.getMonth() + 1);
        const day = (date.getDate());
        return day + "." + month + ".";
    }

    static formatToTime_d_HHmm(date) { // TODO rename to reflect month ...
        // TODO use dnes and zitra if applicable ...
        const day = date.getDate().toString().padStart(2, ' ');
        const month = (date.getMonth() + 1);
        // return day + ". " + month + ". " + this.formatToTime_HHmm(date);
        return this.formatToTime_HHmm(date) + "  " + day + "." + month + ".";
    }

    static formatToTime_HHmm_ddMM_with_slash(date) { // TODO rename to reflect month ...
        // TODO use dnes and zitra if applicable ...
        const day = date.getDate().toString().padStart(2, ' ');
        const month = (date.getMonth() + 1);
        // return day + ". " + month + ". " + this.formatToTime_HHmm(date);
        return this.formatToTime_HHmm(date) + " " + day + "/" + month;
    }

    static formatToTime_ddMM_HHmm(date) { // TODO rename to reflect month ...
        const day = date.getDate().toString().padStart(2, ' ');
        const month = (date.getMonth() + 1);
        return day + "." + month + ". " + this.formatToTime_HHmm(date);
    }

    // TODO needed  ?
    static formatToTime_ddMM_break_HHmm(date) {
        const day = date.getDate().toString().padStart(2, ' ');
        const month = (date.getMonth() + 1);
        const time = this.formatToTime_HHmm(date);
        const dateStr = day + "." + month + ".";
        return time + "\n" + dateStr;
    }

    static formatToTime_HHmm_ddMM(date) {
        const day = date.getDate().toString().padStart(2, ' ');
        const month = (date.getMonth() + 1);
        const time = this.formatToTime_HHmm(date);
        const dateStr = day + "." + month + ".";
        return time + " " + dateStr;
    }

    static formatToTime_dM(date) {
        const day = date.getDate().toString().padStart(2, ' ');
        const month = (date.getMonth() + 1);
        return day + "." + month + ".";
    }

    static formatToTime_d_M(date) {
        const day = date.getDate().toString().padStart(2, ' ');
        const month = (date.getMonth() + 1);
        return day + ". " + month + ".";
    }

    static formatToTime_HHmm(date) {
        const hours = date.getHours().toString().padStart(2, ' ');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return hours + ":" + minutes;
    }

    static formatToTime_Hmm(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return hours + ":" + minutes;
    }


    static tomorrow() {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        return tomorrow;
    }

    static yesterday() {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        return yesterday;
    }

    static offsetDate(date, offsetDays) {
        const prev = new Date(date);
        const offsetDate = new Date(date);
        offsetDate.setDate(prev.getDate() + offsetDays);
        return offsetDate;
    }

    static getDate(date) {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };

        return date.toLocaleDateString(undefined, options);
    }

    static formatDate(date, withMonth, short, includeDesc) {
        // eslint-disable-next-line no-unused-vars
        const dateCz = date.toLocaleDateString("cs-CZ");
        const day = date.getDate() + ".";
        const month = (date.getMonth() + 1) + ".";
        const dateCzShort = day + (withMonth ? " " + month : "");
        const dayOfWeek = date.getDay();
        const daysOfWeekShort = ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'];
        const daysOfWeekLong = ['Neděle', 'Pondělí', 'Úterý', 'Středa', 'Čtvrtek', 'Pátek', 'Sobota'];
        const today = new Date();

        let desc;
        if (dateCz === today.toLocaleDateString("cs-CZ")) {
            desc =  "Dnes";
            if (short) {
                return desc;
            }
        } else if (dateCz === Utils.yesterday().toLocaleDateString("cs-CZ")) {
            desc =  "Včera";
            if (short) {
                return desc;
            }
        } else if (dateCz === Utils.tomorrow().toLocaleDateString("cs-CZ")) {
            desc =  "Zítra";
            if (short) {
                return desc;
            }
        } else {
            if (short) {
                desc = daysOfWeekShort[dayOfWeek];
            } else {
                desc = daysOfWeekLong[dayOfWeek];
            }
        }
        if (includeDesc) {
            return desc + " " + dateCzShort;
        } else {
            return dateCzShort;
        }
    }

    static formatNumber(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(".", ",")
    }

    // e.g. amounts and percentages
    static unformatToNumber(formattedNumber) {
        if (typeof formattedNumber === "number") {
            return formattedNumber;
        } else {
            const unformattedNum = (formattedNumber.replace(/(?![,.])[\D]+/g, "")).replace(",", ".");
            return +unformattedNum;
        }
    }

    static removeWhitespace(text) {
        return text.replace(/\s+/g, "");
    }

    static removeAllChildElements(parent) {
        if (parent.children.length > 0) {
            while (parent.firstChild) {
                parent.removeChild(parent.lastChild);
            }
        }
    }

    static removeChildFromParentElem(child) {
        child.parentElement.removeChild(child);
    }

    static times(num, multiplier) {
        return parseFloat(new Big(num).times(multiplier));
    }

    static div(num, divisor) {
        return parseFloat(new Big(num).div(divisor));
    }

    static sub(num, y) {
        return parseFloat(new Big(num).sub(y));
    }

    static add(num, y) {
        return parseFloat(new Big(num).add(y));
    }


    /**
     * Returns a hash code for a string.
     * (Compatible to Java's String.hashCode())
     *
     * The hash code for a string object is computed as
     *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
     * using number arithmetic, where s[i] is the i th character
     * of the given string, n is the length of the string,
     * and ^ indicates exponentiation.
     * (The hash value of the empty string is zero.)
     *
     * @param {string} s a string
     * @return {number} a hash code value for the given string.
     */
    static hashCode(s) {
        var h = 0, l = s.length, i = 0;
        if (l > 0)
            while (i < l)
                h = (h << 5) - h + s.charCodeAt(i++) | 0;
        return h;
    }

    static monthsToCZYearsMonthsText(monthsCount) {
        if (monthsCount == 0) {
            return this.yearsToCZText(0, true);
        } else if (monthsCount < 12) {
            return this.monthsToCZText(monthsCount);
        } else if (monthsCount == 12) {
            return this.yearsToCZText(1, true);
        } else {
            const months = monthsCount % 12;
            if (months == 0) {
                const years = Math.round(monthsCount / 12);
                return `${this.yearsToCZText(years, true)}`;
            } else {
                const years = Math.floor(monthsCount / 12);
                return `${this.yearsToCZText(years, true)} a ${this.monthsToCZText(months)}`;
            }
        }
    }

    static monthsToCZText(monthsCount) {
        if (monthsCount == 1) {
            return monthsCount + " měsíc";
        } else if (2 <= monthsCount && monthsCount <= 4) {
            return monthsCount + " měsíce";
        } else {
            return monthsCount + " měsíců";
        }
    }

    static yearsToCZText(yearsCount, includeYearNo) {
        let yearText;
        if (yearsCount == 1) {
            yearText = "rok";
        } else if (2 <= yearsCount && yearsCount <= 4) {
            yearText = "roky";
        } else {
            yearText = "let";
        }
        if (includeYearNo) {
            return yearsCount + " " + yearText;
        } else {
            return yearText;
        }
    }

    // deprecated
    static isConvertibleToDecimal(userInput) {
        return this.convertToDecimal(userInput) !== null;
    }

    // deprecated
    static convertToDecimal(userInput) {
        if (userInput !== null && userInput !== undefined) { // we need to accept empty here so that the user can clear the input box!
            userInput = userInput.replace(/\s/, "");
            userInput = Utils.commaToDot(userInput);
            // console.log("userInput = " + userInput);
            const matchesDots = userInput.match(/\./g);
            if (matchesDots !== null && matchesDots.length > 1) { // multiple dots inside the string are invalid ...
                return null; // invalid
            } else {
                userInput = userInput.replace(/\.$/, ""); // one trailing dot without decimals is ignored
            }
            if (userInput.match(/^\+{2,}/)) {
                return null; // invalid
            } else {
                userInput = userInput.replace(/^\+/, "");
            }
            if (userInput === "") {
                return 0;
            }
            if (userInput.match(/^[0-9]+\.?[0-9]*$/)
                || userInput.match(/^[0-9]*\.?[0-9]+$/)
                || userInput.match(/^[0-9]+$/)) {
                return userInput;
            }
        }
        // invalid number ...
        return null;
    }

    // TODO make bounded version as well ...!
    static isConvertibleToInteger(userInput) {
        return this.convertToInteger(userInput) !== null;
    }

    static convertToInteger(userInput) {
        if (userInput !== null && userInput !== undefined) { // we need to accept empty here so that the user can clear the input box!
            if (userInput.match(/^[0-9]*$/)) {
                return userInput;
            } else if (userInput === "") {
                return 0;
            }
        }
        // invalid number ...
        return null;
    }


    static formatCurrency(x) {
        return Utils.formatNumber(x) + " Kč"
    }

    static formatPercentage(x) {
        return Utils.dotToComma(x.toFixed(2)) + " %"
    }

    static dotToComma(x) {
        return x.toString().replace(".", ",");
    }

    static commaToDot(x) {
        return x.toString().replace(",", ".");
    }

    // static searchFieldRecursive(object, fieldName) {
    //     const field = object.key[fieldName];
    //     if (field) {
    //         return field;
    //     } else {
    //         object.key
    //     }
    // }

    static flattenObject(oldObject) {
        const newObject = {};

        flattenHelper(oldObject, newObject, '');

        return newObject;

        function flattenHelper(currentObject, newObject, previousKeyName) {
            for (let key in currentObject) {
                let value = currentObject[key];

                if (value.constructor !== Object) {
                    if (previousKeyName == null || previousKeyName == '') {
                        newObject[key] = value;
                    } else {
                        if (key == null || key == '') {
                            newObject[previousKeyName] = value;
                        } else {
                            newObject[previousKeyName + '.' + key] = value;
                        }
                    }
                } else {
                    if (previousKeyName == null || previousKeyName == '') {
                        flattenHelper(value, newObject, key);
                    } else {
                        flattenHelper(value, newObject, previousKeyName + '.' + key);
                    }
                }
            }
        }
    }

    static checkWideScreen() {
        return window.innerWidth > 768;
    }

}
