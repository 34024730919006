<template>
    <div>

        <picture>
            <source media="(min-width: 1500px)" srcset="../assets/images/sky_2_largest.jpg">
            <source media="(min-width: 1000px)" srcset="../assets/images/sky_2_largest.jpg">
            <source media="(min-width: 500px)" srcset="../assets/images/sky_2_largest.jpg">
            <source media="(min-width: 100px)" srcset="../assets/images/sky_2_largest_narrow.jpg">
            <img src="../assets/images/sky_2_largest.jpg" alt="background image"/>
        </picture>

        <SetFavicon/>

        <NavBar></NavBar>

        <!--        :key="$route.fullPath" .. important for this reason:
                    https://stackoverflow.com/questions/64682708/how-to-force-vue-to-destroy-and-re-create-the-component-on-route-change -->
        <router-view :key="$route.fullPath" style="margin-bottom: 50px"></router-view>

        <Footer></Footer>

    </div>
</template>


<script>
import NavBar from "@/components/NavBar";
import {mapActions, mapGetters} from "vuex";
import Footer from "@/components/Footer";
import {narrowViewPortMixin} from "@/components/reusables/narrow-view-port-mixin";
import {ApiClient} from "@/api/calculations/api-client";
import SetFavicon from "@/components/SetFavicon.vue";
import {Utils} from "@/utils/Utils";

export default {
    name: 'App',
    mixins: [narrowViewPortMixin],
    data() {
        return { // has to stay empty for the mixin to work
        }
    },
    created() {
        console.log("created app")
        ApiClient.postAccess();
    },
    computed: {
        Utils() {
            return Utils
        },
        ...mapGetters('verificationsStore', [])
    },
    methods: {
        ...mapActions('verificationsStore', []),
    },
    components: {SetFavicon, NavBar, Footer}
}

</script>

<style>

/*@import './assets/styles/calc.css';*/

</style>

<style lang="scss">

//@import 'node_modules/bootstrap/scss/bootstrap.scss';
//@import 'node_modules/bootstrap-vue/src/index.scss';


</style>
