<template>
    <div>
        <table>
            <tr>
                <th v-for="(_, index) in windSpeeds" :key="'time-' + index">T{{ index + 1 }}</th>
            </tr>
            <tr>
                <td v-for="(speed, index) in windSpeeds" :key="'speed-' + index">{{ speed }}</td>
            </tr>
            <tr>
                <td :colspan="windSpeeds.length" class="chart-cell">
                    <svg :width="svgWidth" :height="svgHeight">
                        <line v-for="(x, index) in xPositions"
                              :key="'grid-' + index"
                              :x1="x" :y1="0"
                              :x2="x" :y2="svgHeight"
                              stroke="black" stroke-width="1" />
                        <path :d="pathData" stroke="blue" fill="none" stroke-width="2" />
                    </svg>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    data() {
        return {
            windSpeeds: [2, 4, 6, 3, 5, 7, 4, 6, 5.5, 4, 6, 6.5, 6, 7.3, 7.8, 5.5, 2.1, 3, 3.2, 4.2, 4, 4.5], // Example wind speed data
            svgWidth: 1000,
            svgHeight: 70
        };
    },
    computed: {
        xPositions() {
            // Compute x positions for vertical gridlines
            const xSpacing = this.svgWidth / (this.windSpeeds.length - 1);
            return this.windSpeeds.map((_, i) => i * xSpacing);
        },
        pathData() {
            if (this.windSpeeds.length === 0) return "";
            const maxSpeed = Math.max(...this.windSpeeds);
            const xSpacing = this.svgWidth / (this.windSpeeds.length - 1);
            const points = this.windSpeeds.map((speed, i) => {
                let x = i * xSpacing;
                let y = this.svgHeight - (speed / maxSpeed) * (this.svgHeight - 20);
                return `${x},${y}`;
            });

            return "M" + points.join(" L");
        }
    }
};
</script>

<style scoped>
/* Table Styling */
table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

th, td {
    border: 1px solid black; /* Keep vertical borders */
    padding: 10px;
    min-width: 50px;
}

/* SVG inside table cell */
.chart-cell {
    padding: 0;
    text-align: center;
}
svg {
    display: block;
    margin: auto;
    width: 100%;
}
</style>
