<template>
    <div>
<!--        <div class="overlay">-->
        <div v-if="isLoadingData" class="overlay">
            <b-spinner type="grow" label="Loading..."
                    style="background-color: rgba(66,188,233, 1); width: 3rem; height: 3rem; position: absolute">
            </b-spinner>
            <transition name="fade" mode="out-in" appear>
                <div v-if="isLoadingForecasts" :key="loadingText" style="color: #eef8ff; top: 40px; position: relative"
                     class="fade-element fade-enter-active">
                    {{ loadingText }}
                </div>
            </transition>
        </div>
    </div>
</template>

<script>

import {BSpinner} from "bootstrap-vue";
import {mapGetters} from "vuex";

export default {
    name: 'Spinner',
    data() {
        return {
            messages: [
                "předpovědi se stahují",
                "už se to chystá",
                "za chvíli je to u Vás",
                "už je to skoro na cestě"
            ],
            longLoadingMessages: [
                "vypadá to, že to dnes trvá déle",
                "nějaká předpověd se asi zadrhla, ale brzy to bude",
                "ještě malý moment",
                "shromáždit data dnes chvíli trvá",
                "ještě malou chvíli",
                "ještě chvíli, nějaká předpověď se nechce nechat chytit",
            ],
            loadingText: "",
            longLoadingTimeout: null
        }
    },
    computed: {
        ...mapGetters('forecastsStore', ['isLoading', 'isLoadingForecasts']),
        ...mapGetters('verificationsStore', ['isLoadingVerifications', 'isLoadingVerifiedForecasts']),
        isLoadingData() {
            return this.isLoading
                || this.isLoadingForecasts
                || this.isLoadingVerifications
                || this.isLoadingVerifiedForecasts;
        }
    },
    components: {
        BSpinner
    },
    watch: {
        isLoadingData(newVal) {
            if (newVal) {
                this.setLoadingText();
            } else {
                this.clearLoadingText();
            }
        },
    },
    methods: {
        setLoadingText() {
            this.loadingText = this.getRandomMessage(this.messages);
            // console.log("loadingText = " + this.loadingText);
            this.longLoadingTimeout = setTimeout(() => {
                this.loadingText = this.getRandomMessage(this.longLoadingMessages);
                // console.log("loadingText 2 = " + this.loadingText);
            }, 6000);
        },
        clearLoadingText() {
            if (this.longLoadingTimeout) {
                clearTimeout(this.longLoadingTimeout);
            }
            this.loadingText = "";
        },
        getRandomMessage(messages) {
            const randomIndex = Math.floor(Math.random() * messages.length);
            return messages[randomIndex];
        },
    },
}
</script>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

body.grey-out {
    /*opacity: 0.5;*/
}

.fade-enter-active {
    transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
    transition-delay: 1.5s; /* Adjust this value as needed */
}
.fade-enter, .fade-leave-to {
    opacity: 0;
    transform: translateY(-20px); /* Initial position for fade-in effect */
}

</style>
