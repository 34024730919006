<template>

    <tr v-if="isEmptyRow(detailField)" :style="emptyRowStyle()"></tr>
    <tr v-else-if="isHoursRow(detailField)" class="forecast-row" :class="getRowClass(detailField)">
        <td class="forecast-row-header no-border"
            style="background-color: white;">
        </td>
        <td v-for="hour in hours" :key="hour" :style="currentHourStyle(hour)"
            class="detail-hour-cell" style="min-width: 25px;"
            v-bind:ref="'hr-' + hour">
            {{ hour }}
        </td>
    </tr>
    <tr v-else class="forecast-row" :class="getRowClass(detailField)"
        :style="detailRowStyle(detailField)" @click="detailRowClicked(detailField)">
        <td v-if="isSectionTitle(detailField)" colspan="25" class="section-title"
            style="overflow: visible; position: relative; background-color: white; border-left: none; border-right: none; border-top: none">
            <div class="overflowVisible">
                <div class="sticky-row-title">
                    <img class="row-title-icon notranslate" :src="require('@/assets/icons/' + getRowTitleIcon(detailField))"/>
                    {{ getDetailName(detailField) }}
                </div>
            </div>
        </td>
        <td v-if="!isSectionTitle(detailField)" class="forecast-row-header cell" :style="detailNameStyle(detailField)" nowrap>
            <div class="forecast-row-name d-flex align-items-end align-content-end">
                {{ getDetailName(detailField) }}
                <span v-if="showExpandDown(detailField)" class="material-symbols-outlined expand-detail-icon notranslate">
                    expand_circle_down
                </span>
                <span v-else-if="showExpandUp(detailField)" class="material-symbols-outlined expand-detail-icon notranslate">
                    expand_circle_up
                </span>
            </div>
        </td>
        <td v-for="hour in hours" :key="hour" class="cell" :class="detailCellClass(detailField)" style="height: 28px;" :style="detailCellStyle(hour, detailField)">
            <div v-if="getHourForecastDetail(hour, getDetailedForecast(hour), detailField)
                && detailField === 'seeing'"
                 class="sky-quality-symbol-parent">
                <SkyQuality mode="seeing" :value="getHourForecastDetail(hour, getDetailedForecast(hour), detailField)"></SkyQuality>
            </div>
            <div v-else-if="getHourForecastDetail(hour, getDetailedForecast(hour), detailField)
                && detailField === 'transparency'"
                 class="sky-quality-symbol-parent">
                <SkyQuality mode="transparency" :value="getHourForecastDetail(hour, getDetailedForecast(hour), detailField)"></SkyQuality>
            </div>
            <div v-else-if="getDetailedForecast(hour)
                    && detailField.includes('windAngle')
                    && getWindArrowStyle(hour, getDetailedForecast(hour), detailField)"
                 class="cell-value">
                    <span class="material-icons notranslate" :style="getWindArrowStyle(hour, getDetailedForecast(hour), detailField)">
                        trending_flat
                    </span>
            </div>
            <div v-else>
                <div v-if="getHourForecastDetail(hour, getDetailedForecast(hour), detailField) !== null"
                     class="cell-value">
                    {{ getHourForecastDetail(hour, getDetailedForecast(hour), detailField, true) }}
                    <div v-if="showChart(detailField)" :style="chartBarStyle(detailField, hour)"></div>
                </div>
                <div v-else class="no-data">
                    -
                </div>
            </div>
        </td>
    </tr>


</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {utilsMixin} from "@/components/reusables/utils-mixin";
import {BFormRadio, VBToggle} from 'bootstrap-vue';
import Vue from "vue";
import {Sources} from "@/config/sources";
import SkyQuality from "@/components/forecast/SkyQuality";
import {Utils} from "@/utils/Utils";
import {Colors} from "@/utils/colors";
import {ForecastDetailsUtils} from "@/store/modules/forecast-details-store";
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-toggle', VBToggle)


export default {
    name: 'ForecastTableDetailRow',
    mixins: [utilsMixin],
    props: {
        detailField: null
    },
    mounted() {
    },
    beforeDestroy() {
    },
    watch: {
    },
    data() {
        return {
        }
    },
    computed: {
        Utils() {
            return Utils
        },
        ...mapGetters('forecastsStore', [
                'getDetailedForecast', 'shouldCenterOnMidnight', 'isDisplayedWholeCountry',
                'getHourForecast', 'hours']),
        ...mapGetters('forecastDetailsStore',
            ['getLastForecastDetailSource', 'getDetails', 'getExpandedDetails',
                'getExpandableDetails', 'getChartedDetails', 'getForecastDetailValues',
                'isShowDetailChartsForSources', 'getDetailChartHeightPx', 'getDetailChartRowHeightPx',
                'getDetailValueRounding']),
        ...mapGetters('forecastStylesStore',
            ['getTableCenteringClass', 'currentHourStyle']),
    },
    methods: {
        ...mapActions('forecastDetailsStore', ['toggleExpandableDetail']),
        getDetailName(field) {
            let detailSourceCode = ForecastDetailsUtils.getDetailSourceCode(field);
            if (detailSourceCode) {
                return Sources.displayName(detailSourceCode);
            } else {
                let detailKey = ForecastDetailsUtils.getDetailKey(field);
                return this.getDetails.get(detailKey).display;
            }
        },
        getRowTitleIcon(field) {
            let detailKey = ForecastDetailsUtils.getDetailKey(field);
            return this.getDetails.get(detailKey).icon;
        },
        isSectionTitle(field) {
            if (field.includes(".")) {
                // if contains fot then it's source breakdown ...
                return false;
            }
            return this.getDetails.get(field).divider;
        },
        isEmptyRow(field) {
            return ForecastDetailsUtils.isEmptyRow(field);
        },
        emptyRowStyle() {
            if (Utils.checkWideScreen()) {
                return "height: 12px;"
            } else {
                return "height: 12px;"
            }
        },
        isHoursRow(field) {
            if (field.includes(".")) {
                // if contains fot then it's source breakdown ...
                return false;
            }
            return this.getDetails.get(field).hoursRow;
        },
        getRowClass(detailField) {
            let cssClass = this.isSectionTitle(detailField)
                ? ''
                : 'forecast-row-shadow';
            cssClass += " ";
            cssClass += this.getTableCenteringClass;
            return cssClass;
        },
        detailCellStyle(hour, detailField) {
            if (this.isSectionTitle(detailField)) {
                // cannot have conditional logic in v-for element generation so this is a workaround for this
                return "display: none";
            }

            let style = "";

            const value = this.getHourForecastDetail(hour, this.getDetailedForecast(hour), detailField, true);

            if (value === null) {
                style += `background-color: ${Colors.GREY_5}`;
            }

            const neutralColor = "rgb(75,75,75)";
            const warningColor = "rgb(227,119,29)";
            const criticalColor = "rgb(206,58,14)";
            const greatColor = "rgb(26,114,1)";
            // const neutralColor = "rgb(75,75,75)";
            // const warningColor = neutralColor;
            // const criticalColor = neutralColor;
            // const greatColor = neutralColor;

            const warningStyle = `color: ${warningColor};`;
            const criticalStyle = `color: ${criticalColor};`;
            const greatStyle = `color:  ${greatColor};`;
            const neutralStyle = `color:  ${neutralColor};`;

            if ((value === 0 || value) && value !== '-') {

                const detailKey = ForecastDetailsUtils.getDetailKey(detailField);
                const settings = this.getDetails.get(detailKey);
                const detailSourceCode = ForecastDetailsUtils.getDetailSourceCode(detailField);
                const isSourceDetail = detailSourceCode !== null;
                const chartSettings = this.getChartedDetails.get(detailKey);

                if ((!isSourceDetail || this.isShowDetailChartsForSources) && chartSettings) {
                    // TODO leavig this commented out in case we want to introduce this
                    // const anyNonZeroValuesInRow = this.hours.some(h => {
                    //     const value = this.getHourForecastDetail(h, this.getDetailedForecast(h), detailField);
                    //     return value !== null && value !== 0;
                    // });
                    // if (anyNonZeroValuesInRow) {
                    const height = this.getDetailChartRowHeightPx ? this.getDetailChartRowHeightPx : 50;
                    style += `height: ${height}px; vertical-align: bottom;`;
                    // }
                }

                if (!settings.highlight) {
                    style += neutralStyle;
                } else if (detailKey === 'dewPoint') {
                    // here value is the dewPoint value ...
                    let temperatureField = ForecastDetailsUtils.toDetailKey("temperature", detailSourceCode);
                    const temperature = this.getHourForecastDetail(hour, this.getDetailedForecast(hour), temperatureField, true);
                    if (temperature !== null) {
                        if ( temperature - value <= 1) {
                            style += criticalStyle;
                        } else if (temperature - value <= 2) {
                            style += warningStyle;
                        }
                    }
                } else {
                    if (value >= settings.criticalMin) {
                        style += criticalStyle;
                    } else if (value >= settings.warningMin) {
                        style += warningStyle;
                    } else if (value <= settings.criticalMax) {
                        style += criticalStyle;
                    } else if (value <= settings.warningMax) {
                        style += warningStyle;
                    } else if (value <= settings.greatMax) {
                        style += greatStyle;
                    } else if (value >= settings.greatMin) {
                        style += greatStyle;
                    } else {
                        style += neutralStyle;
                    }
                }

            }
            return style;
        },
        detailCellClass(detailField) {
            const isSourceDetail = ForecastDetailsUtils.getDetailSourceCode(detailField) !== null;
            let classes = "";
            if (isSourceDetail) {
                classes += " expanded-detail";
            }
            let detailKey = ForecastDetailsUtils.getDetailKey(detailField);
            if (this.getChartedDetails.get(detailKey)) {
                classes += " charted-detail";
            }
            return classes;
        },
        detailNameStyle(detailField) {
            let styles = "";
            let detailKey = ForecastDetailsUtils.getDetailKey(detailField);
            if (this.getExpandedDetails.includes(detailKey)) {
                let isSourceDetail = null !== ForecastDetailsUtils.getDetailSourceCode(detailField);
                let expandedBackground = isSourceDetail ? "rgb(253,247,187)" : "rgb(253,241,145)";
                styles += "background-color: " + expandedBackground;
                styles += isSourceDetail ? "; padding-left: 12px" : "";
            }
            return styles;
        },
        detailRowStyle(detailField) {
            if (this.isDisplayedWholeCountry) {
                return "";
            }
            let detailSourceCode = ForecastDetailsUtils.getDetailSourceCode(detailField);
            if (detailSourceCode) {
                // row for source breakdown
                let styles = "cursor: pointer; color: " + Colors.GREY_4;
                // console.log(this.getLastForecastDetailSource(detailField))
                if (this.getLastForecastDetailSource(detailField) === detailSourceCode) {
                    // box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
                    console.log("Here ....")
                    styles += `; box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);`
                }
                return styles;
            } else if (!this.isSectionTitle(detailField)) {
                // main detail row
                let detailKey = ForecastDetailsUtils.getDetailKey(detailField);
                let styles = "";
                if (this.getExpandableDetails.includes(detailKey)) {
                    styles += "cursor: pointer;"
                }
                if (this.getExpandedDetails.includes(detailKey)) {
                    // shading of the top row of the group
                    styles += `; box-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, 0.3); `
                }
                return styles;
            }
            return "";
        },
        detailRowClicked(detailField) {
            if (this.isDisplayedWholeCountry) {
                return;
            }
            let detailKey = ForecastDetailsUtils.getDetailKey(detailField);
            if (this.getExpandableDetails.includes(detailKey)) {
                this.toggleExpandableDetail(detailKey);
            }
        },
        showExpandUp(detailField) {
            if (this.isDisplayedWholeCountry) {
                return false;
            }
            let detailKey = ForecastDetailsUtils.getDetailKey(detailField);
            const isSourceDetail =  ForecastDetailsUtils.getDetailSourceCode(detailField);
            if (!isSourceDetail && this.getExpandableDetails.includes(detailKey)) {
                if (this.getExpandedDetails.includes(detailKey)) {
                    return true;
                }
            }
            return false;
        },
        showExpandDown(detailField) {
            if (this.isDisplayedWholeCountry) {
                return false;
            }
            let detailKey = ForecastDetailsUtils.getDetailKey(detailField);
            const isSourceDetail =  ForecastDetailsUtils.getDetailSourceCode(detailField);
            if (!isSourceDetail && this.getExpandableDetails.includes(detailKey)) {
                if (!this.getExpandedDetails.includes(detailKey)) {
                    return true;
                }
            }
            return false;
        },
        getHourForecastDetail(hour, forecast, detailField, applyRounding = false) {
            if (forecast) {
                const detailKey = ForecastDetailsUtils.getDetailKey(detailField);
                const detailSourceCode = ForecastDetailsUtils.getDetailSourceCode(detailField);
                let hourly = this.getHourForecast(hour, forecast, detailSourceCode);
                if (hourly) {
                    const value = hourly[detailKey];
                    if (value || value === 0) {
                        let detailValueFormatter = this.getDetailValueRounding(detailField);
                        if (applyRounding && detailValueFormatter && detailValueFormatter.displayValue) {
                            return detailValueFormatter.displayValue(value);
                        } else {
                            return value;
                        }
                    }
                }
            }
            return null;
        },

        getWindArrowStyle(hour, forecast, name) {
            const value = this.getHourForecastDetail(hour, forecast, name);
            if (value && value !== '-') {
                let degrees = 90 + value; // +90 to make the start position point down North -> South
                return `transform: rotate(${degrees}deg); font-size: 15px`;
            }
            return null;
        },

        // TODO perhaps move to store?
        showChart(detailField) {
            const detailKey = ForecastDetailsUtils.getDetailKey(detailField);
            const isSourceDetail = ForecastDetailsUtils.getDetailSourceCode(detailField) !== null;
            const chartSettings = this.getChartedDetails.get(detailKey);
            if (chartSettings && (!isSourceDetail || this.isShowDetailChartsForSources)) { // not source breakdown ...
                return true;
            }
            return false;
        },

        chartBarStyle(detailField, hour) {
            let value = this.getHourForecastDetail(hour, this.getDetailedForecast(hour), detailField);

            const visibility = "#C1E1C1";
            const fog = "#D3D3D3";
            const aerosol550 = "#d0d5ff";
            const positiveTemp = "rgb(246,158,116)";
            const negativeTemp = "#9ddeff";
            const windGusts = "#f1d187";
            const wind = windGusts;
            const humidity = "rgb(123,193,253)";
            const precipitation = "#8acece";
            const snow = "#e4f5f8";

            const visibilitySource = "#E1F1E1";
            const fogSource = "#e5e5e5";
            const aerosol550Source = "rgba(208,213,255,0.78)";
            const positiveTempSource = "rgba(246,158,116,0.61)";
            const negativeTempSource = "rgba(157,222,255,0.56)";
            const windGustsSource = "rgba(241,209,135,0.59)";
            const windSource = windGustsSource;
            const humiditySource = "rgba(163,217,255,0.68)";
            const precipitationSource = "rgba(162,226,226,0.73)";
            const snowSource = "rgba(228,245,248,0.52)";

            const detailKey = ForecastDetailsUtils.getDetailKey(detailField);
            const isSourceDetail = null !== ForecastDetailsUtils.getDetailSourceCode(detailField);

            let backgroundColor = Colors.BLUE_2;


            if (["feelsLike", "temperature", "dewPoint"].includes(detailKey)) {
                if (value > 0) {
                    backgroundColor = isSourceDetail ? positiveTempSource : positiveTemp;
                } else {
                    backgroundColor = isSourceDetail ? negativeTempSource : negativeTemp;
                }
            } else if (["windSpeed"].includes(detailKey)) {
                backgroundColor = isSourceDetail ? windSource : wind;
            } else if (["windGusts"].includes(detailKey)) {
                backgroundColor = isSourceDetail ? windGustsSource : windGusts;
            } else if ("fog" === detailKey) {
                backgroundColor = isSourceDetail ? fogSource : fog;
            } else if ("relativeHumidity" === detailKey) {
                backgroundColor = isSourceDetail ? humiditySource : humidity;
            } else if ("precipitation" === detailKey) {
                backgroundColor = isSourceDetail ? precipitationSource : precipitation;
            } else if ("visibility" === detailKey) {
                backgroundColor = isSourceDetail ? visibilitySource : visibility;
            } else if ("aerosol550" === detailKey) {
                backgroundColor = isSourceDetail ? aerosol550Source : aerosol550;
            } else if (["showfall", "snowDepth"].includes(detailKey)) {
                backgroundColor = isSourceDetail ? snowSource : snow;
            }

            // let detailSource = ForecastDetailsUtils.getDetailSourceCode(detailField);
            const chartSettings = this.getChartedDetails.get(detailKey);
            if (chartSettings) { // only for those that have chart settings we should proceed
                let valueAbs = Math.abs(value);
                if (chartSettings.valueMapper) {
                    valueAbs = chartSettings.valueMapper(valueAbs)
                    // console.log("valueAbs = " + valueAbs + " for value = " + value)
                }
                const max = chartSettings.maxValue;
                let maxBarHeight = this.getDetailChartHeightPx ? this.getDetailChartHeightPx : 38;
                // console.log("getDetailChartHeightPx = " + this.getDetailChartHeightPx)
                // console.log("maxBarHeight = " + maxBarHeight)
                let height = valueAbs / max * maxBarHeight;
                height = height <= 0 ? 0 : height;
                // console.log(detailKey + ": height = " + height + " out of max = " + maxBarHeight)
                return `background-color: ${backgroundColor}; height: ${height}px;`;
            } else {
                return "display:none";
            }
        },

    },
    components: {
        // eslint-disable-next-line vue/no-unused-components
        BFormRadio,
        SkyQuality,
    }
}
</script>



<style scoped>

.section-title {
    text-align: center;
    font-weight: 300;
}

.sky-quality-symbol-parent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sticky-row-title {
    max-width: calc(100vw - 26px);
    position: sticky;
    left: 0;
    bottom: 0;
    line-height: 1.66;
    background-color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    margin-top: 14px;
    margin-bottom: 9px;
    height: 20px;
}

.row-title-icon{
    height: 20px;
    margin-right: 4px;
    margin-bottom: 5px;
}

.expand-detail-icon {
    font-size: 15px;
    margin-left: auto;
    color: #868686;
}

.source-detail-radio-btn {
    margin-left: auto;
    //color: #868686;
}


.no-border td {
    border: none;
}

</style>

