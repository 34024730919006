<template>
<!--    TODO make the border for this one darker between the cells ...-->
    <td class="cell day-night-cell no-center-border" :style="getDayNightStyle(hour)" style="cursor: pointer"
        v-on:click="toggleDisplayMoon">
        <div class="moon-hourly-image-parent">
            <div v-if="this.showMoon(hour)" class="moon-hourly-image-container" :style="getImageContainerClass(hour)" >
                <img class="image" :src="require('@/assets/images/' + this.getMoonImage(hour))" alt="Moon"
                     style="z-index: 10;"/>
            </div>
            <div v-if="getDyHourIntervals(hour)" style="height: 28px">
                <Constellations :hour="hour" :moon-visible="showMoon(hour)"></Constellations>
            </div>
            <div v-else style="height: 28px; background-color: #f5f5f5">
            </div>
        </div>
    </td>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Utils} from "@/utils/Utils";
import Constellations from "@/components/forecast/Constellations.vue";

export default {
    name: "DayNightHourCell",
    props: {
        hour: null
    },
    computed: {
        Utils() {
            return Utils
        },
        ...mapGetters('forecastsStore',
            ['showMoon', 'getMoonImage', 'getMoonHours', 'getDyHourIntervals']),
        ...mapGetters('forecastStylesStore',
            ['getTableCenteringClass']),
    },
    watch: {
    },
    data() {
        return {
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['toggleDisplayMoon']),
        getDayNightStyle(hour) {
            // const col = "background: linear-gradient(to right, #ffcc00 0%, #ffcc00 40%, #ff9900 50%, #ff6200 60%, #ff6200 100%);";
            const hourValues = this.getDyHourIntervals(hour);
            if (hourValues) {
                let colors = "";
                let sum = 0;
                // eslint-disable-next-line no-unused-vars
                for (const [idx, hourValue] of hourValues.entries()) {
                    if (hourValue.size > 0) {
                        let coef;
                        if (hourValue.size < 20) coef = 1.0;
                        if (hourValue.size >= 20) coef = 0.8;
                        if (hourValue.size >= 40) coef = 0.5;
                        if (hourValue.size >= 50) coef = 0.6;
                        if (hourValue.size >= 60) coef = 0.3;
                        if (hourValue.size === 100) coef = 1.0;

                        let hintSum = sum + hourValue.size * coef;
                        sum = sum + hourValue.size;
                        let color = "";

                        if (hourValue.type === 'EMPTY') color = '#ffffff';
                        if (hourValue.type === 'DAY') color = '#ffe971';
                        if (hourValue.type === 'CIVIL_TWILIGHT') color = '#ffc44c';
                        if (hourValue.type === 'NAUTICAL_TWILIGHT') color = '#0085cc';
                        if (hourValue.type === 'ASTRONOMICAL_TWILIGHT') color = '#012cb7';
                        if (hourValue.type === 'ASTRONOMICAL_NIGHT') color = '#011554';

                        let colorSection = `, ${color} ${hintSum}% ${sum}%`
                        // console.log(`idx = ${idx};hour = ${hour}; valueSize = ${hourValue.size}; colorSection = ${colorSection}`);
                        colors = colors + colorSection;
                    }
                }
                const background = `background: linear-gradient(to right${colors})`;
                // console.log(background); // TODO find out why this method is run whenever we hover over date arrows ...
                return background;
            }
            return '';
        },
        getImageContainerClass(hour) {
            const moonHours = this.getMoonHours(hour);
            let horizontal = 0;
            // eslint-disable-next-line no-unused-vars
            let vertical = 0;

            if (moonHours) {
                const hd = moonHours[hour];

                // some defaults
                horizontal = -50; // (-120 ... 20)
                vertical = 75;

                let horiz1 = {5: -120, 4: -85, 3: -50, 2: -15, 1: 20};
                let horiz2 = {5: 20, 4: -15, 3: -50, 2: -85, 1: -120};

                const parts = Math.floor(hd.minutesVisible / 12) + 1;
                if (hd.order === 1) {
                    horizontal = horiz1[parts];
                    // console.log("hd.minutesVisible = " + hd.minutesVisible + ", parts = " + parts)
                } else if (hd.order === hd.hoursCount) {
                    // console.log("hd.minutesVisible = " + hd.minutesVisible + ", parts = " + parts)
                    horizontal = horiz2[parts];
                }

                const min = 1;
                const maxMax = 110;
                const maxPossibleMoonAlt = 100; // lets assume that the upper border of the box is zenith
                let max;

                if (hd.maxAltitude) {
                    max = (hd.maxAltitude * (maxMax / maxPossibleMoonAlt));
                } else {
                    if (hd.hoursCount >= 14) {
                        max = 100;
                    } else if (hd.hoursCount >= 10) {
                        max = 80;
                    } else {
                        max = 60;
                    }
                }

                const even = hd.hoursCount % 2 === 0;

                // works for even numbers ...
                let count;
                let c;
                if (even) {
                    count = Math.floor(hd.hoursCount / 2 + 0.1);
                    c = count - 1;
                } else {
                    count = Math.ceil(hd.hoursCount / 2);
                    c = count - 1;
                }

                // calculation
                // a2 + b2 = c2
                // a=V c2 - b2

                let b = 0;

                if (even) {
                    if (hd.order < count) {
                        b = count - hd.order;
                    } else if (hd.order === count || hd.order === count + 1) { // top positions
                        b = 0;
                    } else {
                        // 6 - 4 - 1 -> b = 1
                        b = hd.order - count - 1;
                    }
                } else {
                    if (hd.order < count) {
                        // 5 - 1 -> b = 4
                        b = count - hd.order;
                    } else if (hd.order === count) {
                        b = 0;
                    } else {
                        // 6 - 5 -> b = 1
                        b = hd.order - count;
                    }
                }

                // console.log("date = " + hd.date + "; hoursCount = " + hd.hoursCount + "; order = " + hd.order + "; count = " + count + "; b = " + b+ "; c = " + c);

                let vertical = min + Math.floor(Math.pow(Math.pow(c, 2) - Math.pow(b, 2), 0.5) * max / count);

                // console.log("vertical = " + vertical);

                return `transform: translate(${horizontal}%, -${vertical}%);`;
            } else {
                return '';
            }
        },
    },
    components: {
        Constellations
    }
}
</script>

<style>

.image {
    width: 100%; /* Set the width of the image to fill the container */
    height: auto; /* Automatically adjust the height to maintain the image's aspect ratio */
}

.moon-hourly-image-parent {
    /* constellations depend on this*/
    position: relative; /* Set the parent element as a positioning context */
}

.moon-hourly-image-container {
    position: absolute;
    top: 50%; /* Position the container at the vertical center of the parent element */
    left: 50%; /* Position the container at the horizontal center of the parent element */
    width: 38%;
}

</style>
