<template>
    <div>

        <!--        <h5 style="margin-top: 30px; margin-bottom: 10px">Posledních {{hours}} hodin</h5>-->

        <div class="table-responsive" style="margin-right: 10px">
            <table class="table table-sm table-striped table-borderless" style="background-color: white">
                <thead class="text-center font-size-sm ">
                    <tr>
                        <th class="verif-header sticky-left">Model</th>
                        <th class="verif-header">Předpověď
                            <Tooltip :id="tooltipId('predpoved')" text="Průměrná předpověď celkové oblačnosti"></Tooltip>
                        </th>
                        <th class="verif-header">Realita
                            <Tooltip :id="tooltipId('realita')" text="Průměrná naměřená úroveň celkové oblačnosti"></Tooltip>
                        </th>
                        <th class="verif-header"><span class="text-nowrap">Ø Prostá</span> odchylka
                            <Tooltip :id="tooltipId('odchylka')" text="Průměrný rozdíl mezi naměřenou hodnotou oblačnosti
                            a předpovídanou hodnotou oblačnosti ve stejný čas a lokalitě."></Tooltip>
                        </th>
                        <th class="verif-header"><span class="text-nowrap">Ø Absolutní</span> odchylka
                            <Tooltip :id="tooltipId('abs-odchylka')" text="Průměr absolutních hodnot rozdílů mezi nameřenou hodnotou oblačnosti
                            a předpovídanou hodnotou oblačnosti ve stejný čas a lokalitě"></Tooltip>
                        </th>
                        <th class="verif-header">Počet měření
                            <Tooltip :id="tooltipId('records')" text="Počet měření ČHMÚ, které byly použity pro srovnání s předpověďmi pro vybrané lokality a časový úsek"></Tooltip>
                        </th>
                    </tr>
                </thead>

                <!--                    colour range: http://jsfiddle.net/maniator/tKrM9/53/-->
                <tbody class="text-nowrap font-size-sm">
                    <tr v-for="verification in getPastHoursVerification(hours)" v-bind:key="verification.source">
                        <td style="text-align: left" class="sticky-left notranslate">
                            {{ verification.sourceTitle }}
                        </td>
                        <td class="padded-number">
                            {{ padded(verification.avgForecastCloudTotal) }} <span style="font-size: 10px">%</span>
                        </td>
                        <td class="padded-number">
                            {{ padded(verification.avgMeasuredCloudTotal) }} <span style="font-size: 10px">%</span>
                        </td>
                        <td class="padded-number">
                            {{ padded(verification.avgDiff) }} <span style="font-size: 10px">%</span>
                        </td>
                        <td class="padded-number">
                            {{ padded(verification.avgDiffAbs) }} <span style="font-size: 10px">%</span>
                        </td>
                        <td class="padded-number">
                            {{ padded(verification.recordCount) }}
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>

    </div>

</template>

<script>
import {mapGetters} from 'vuex';
import {utilsMixin} from "@/components/reusables/utils-mixin";
import {BCard} from 'bootstrap-vue';
import {BCardHeader} from 'bootstrap-vue';
import {BCollapse} from 'bootstrap-vue';
import {BCardBody} from 'bootstrap-vue';
import {BCardText} from 'bootstrap-vue';
import {BButton} from 'bootstrap-vue';
import {VBToggle} from 'bootstrap-vue';
import {BIconInfoCircle} from 'bootstrap-vue';
import {BTooltip} from 'bootstrap-vue';
import Vue from "vue";
import Tooltip from "@/components/Tooltip.vue";
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-toggle', VBToggle)


export default {
    name: 'ForecastPastHourVerificationTable',
    mixins: [utilsMixin],
    props: {
        hours: Number
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters('forecastsStore', ['getPastHoursVerification']),
    },
    methods: {
        tooltipId: function (header) {
            return header + "-info-icon-" + this.hours;
        }
    },
    // eslint-disable-next-line vue/no-unused-components
    components: {Tooltip, BCollapse, BCardBody, BCardHeader, BCard, BButton, BCardText, BIconInfoCircle, BTooltip}
}
</script>

<style scoped>

.verif-header {
    vertical-align: top;
}

td, th {
    font-size: 12px;
}

</style>
