<template>
    <div class="parent">
        <div class="user-tip">
            <span class="material-icons notranslate" style="font-size: 16px">settings</span>
            <span style="margin-left: 5px">TIP</span>
        </div>
        <div class="text-container">
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tip',
    props: {
        text: String,
    },
}
</script>

<style scoped>

.user-tip {
    font-size: 16px;
    font-weight: 600;
    color: #575757;
    padding: 3px 9px 3px 9px;
    background: #dbf6ce;
    border-radius: 8px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.text-container {
    color: dimgrey;
    display: flex;
}

.parent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

</style>
