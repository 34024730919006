export class Colors {
    static WHITE = "rgb(255, 255, 255)"
    static BLACK = "rgb(0, 0, 0)"
    static BLACK_2 = "rgb(61, 61, 61)"
    static GREY_1 = "rgb(136,136,136)"
    static GREY_2 = "rgb(215,199,199)"
    static GREY_3 = "rgb(252,248,248)"
    static GREY_4 = "rgb(103,102,102)"
    static GREY_5 = "#f5f5f5";
    static YELLOW_1 = "rgb(253,243,0)"
    static YELLOW_2 = "rgb(253,247,187)"
    static BLUE_1 = "rgb(220,242,250)"
    static BLUE_2 = "rgb(159,159,159, 0.6)"
    // static BLUE_2 = "rgb(125,191,244)"
    // static BLUE_2 = "rgb(125,191,244, 1)"
    static RED_1 = "#f59393"
    static PINK_1 = "rgb(252,220,220)"
    static SOME = "rgb(130 130 130)"

}
