<template>
    <div>

        <b-button size="sm" @click="togglePopup" variant="secondary">
            <SettingsIcon name="settings"></SettingsIcon>
            Velikost širšího regionu
            <span style="color: #8c8c8c">{{    getRadiusDivider() }}</span>
            <span style="font-weight: 600">{{ getRadius() }}</span>
        </b-button>

        <div class="overlay" v-if="popupDisplayed" @click="togglePopup"></div>

        <Transition>
            <div v-if="popupDisplayed" class="popup">
                <div class="ml-auto mr-3" v-on:click="togglePopup">
                    <span class="popup-close">&times;</span>
                </div>
                <div style="text-align: center; margin-bottom: 20px">
                    <h4 class="popup-title">S jak velkým regionem počítat?</h4>
                    <hr/>
                    <div>
                        <h6>Velikost regionu:</h6>
                        <b-form-select v-model="reliabilityRadius" size="sm" :options="radiusOptions" right variant="primary"></b-form-select>
                    </div>
                    <div v-if="isDisplayedWholeCountry" class="small-warning-text" style="text-align: left; font-size: 11px; font-style:
                    italic; margin-left: 8px; margin-top: 6px">
                        *Při zobrazení celé ČR jsou brána data z celého území bez ohledu na volbu
                    </div>
                    <p style="margin-top: 15px" class="popup-text">
                        Širší region je okruh kolem vámi zvolené lokality.
                        Určuje, kolik měření reálného stavu oblačnosti za posledních 24 hodin je relevantních
                        pro vyhodnocení aktuální přesnosti modelů pro tuto lokalitu (barevné "kartičky" u jména modelů).
                    </p>
                    <p class="popup-text">
                        Podobně nastavení určuje také rozsah vstupních dat pro jednotlivé grafy na této stránce.
                    </p>

                    <b-button @click="clickOk" variant="primary" >
                        Nastavit
                    </b-button>
                </div>
            </div>
        </Transition>

    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BButton, BFormSelect } from "bootstrap-vue";
import SettingsIcon from "@/components/forecast/SettingsIcon.vue";

export default {
    name: "WiderRegionSettingsPopup",
    computed: {
        ...mapGetters('forecastsStore', ['isDisplayedWholeCountry', 'getReliabilityMeasurementCount',
            'getForecastReliabilityRadius']),
    },
    mounted() {
        this.reliabilityRadius = this.getForecastReliabilityRadius; // TODO this causes the API to be called twice ...
        console.log("mounted forecastReliabilityRadius = " + this.getForecastReliabilityRadius)
        this.setRadiusOptionTextAndValues();
    },
    data() {
        return {
            popupDisplayed: false,
            reliabilityRadius: undefined,
            radiusOptions: [
                { value: 'RADIUS_WHOLE_COUNTRY', displayText: 'Celá ČR' },
                { value: 'RADIUS_200', displayText: 'Okruh 200 km' },
                { value: 'RADIUS_150', displayText: 'Okruh 150 km' },
                { value: 'RADIUS_100', displayText: 'Okruh 100 km' },
                { value: 'RADIUS_50', displayText: 'Okruh 50 km' },
            ]
        }
    },
    watch: {
        reliabilityRadius: function (newValue, oldValue) {
            console.log("watch ... reliabilityRadius oldValue = " + oldValue + "; newValue = " + newValue)
            if (oldValue !== undefined) {
                console.log("CHANGING reliabilityRadius = " + newValue);
                this.changeForecastReliabilityRadius(newValue);
            }

        },
    },
    methods: {
        // we need to reload whole forecasts as the region affects a lot of stuff
        ...mapActions('forecastsStore', ['reloadForecasts', 'changeForecastReliabilityRadius']),
        clickOk() {
            this.togglePopup();
            this.reloadForecasts();
        },
        togglePopup() {
            this.popupDisplayed = !this.popupDisplayed;
            if (this.popupDisplayed) {
                this.$emit('childPopupOpened');
            } else {
                this.$emit('childPopupClosed');
            }
        },
        setRadiusOptionTextAndValues() {
            this.radiusOptions.forEach(o => {
                o.text = o.displayText + this.getMeasurements(o.value);
            })
        },
        getMeasurements(radius) {
            const count = this.getReliabilityMeasurementCount(radius);
            if (count != null && count >= 0) {
                return ` (${count} dostupných měření)`;
            }
            return "";
        },
        getRadiusDivider() {
            if (this.getRadius()) {
                return  " \u2022 ";
            }
            return "";
        },
        getRadius() {
            let radius = this.reliabilityRadius;
            if (radius) {
                const number = radius.match(/\d+/);
                if (number) {
                    return number[0] + " km";
                }
                if (radius.includes("WHOLE_COUNTRY")) {
                    return "ČR";
                }
            }
            return "";
        },
    },
    components: {
        SettingsIcon,
        BButton,
        BFormSelect
    }
}
</script>

<style scoped>


</style>
