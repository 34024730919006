<template>
    <div>

        <b-button size="sm" @click="togglePopup" variant="secondary">
            <SettingsIcon name="settings"></SettingsIcon>
            Povětrnostní podmínky
        </b-button>

        <div class="overlay" v-if="popupDisplayed" @click="togglePopup"></div>

        <Transition>
            <div v-if="popupDisplayed" class="popup">
                <div class="ml-auto mr-3" v-on:click="togglePopup">
                    <span class="popup-close">&times;</span>
                </div>
                <div style="text-align: center; margin-bottom: 24px">
                    <h4 class="popup-title">Zobrazení meteorologických ukazatelů</h4>

                    <hr/>

                    <div v-if="isDisplayedWholeCountry" class="small-warning-text" style="text-align: left; font-size: 11px; font-style:
                    italic; margin-left: 8px; margin-top: 6px; margin-bottom: 12px">
                        *Volby jsou aplikovány na předpovědi konkretní lokality. Při zobrazení celé ČR jsou data průměrována za všechny modely.
                    </div>

                    <div style="margin-bottom: 15px">
                        <h6 class="section-title">Jaká data zobrazovat?</h6>
                        <p class="popup-text">Níže nastavíte, data jakého modelu budou přednostně zobrazena pro každý ukazatel (teplota, vítr ...).
                            Každý ukazatel je vyhodnocován samostatně.
                        </p>
                        <div class="control">
                            <b-form-radio-group v-model="detailSourceMode" name="radius-options" stacked
                                               @change="touchedSourceModeSettings">
                                <b-form-radio value="LEAST_FAVOURABLE" class="mode-option">Nejméně příznivý model </b-form-radio>
                                <b-form-radio value="MOST_FAVOURABLE" class="mode-option">Nejvíce příznivý model</b-form-radio>
                                <b-form-radio value="AVERAGE" class="mode-option">Průměr dat modelů</b-form-radio>
                                <b-form-radio value="USER_PREFERRED_SOURCE_ORDER" class="mode-option">Nastavit vlastní preferovaný model:</b-form-radio>
                            </b-form-radio-group>
                        </div>
                        <div class="sub-control">
                            <b-form-select v-model="preferredDetailSource" :options="availableDetailSources"
                                           size="sm" variant="primary" class="my-select"
                                           :disabled="detailSourceMode !== 'USER_PREFERRED_SOURCE_ORDER'"
                                            @change="touchedSourceModeSettings">
                            </b-form-select>
                        </div>
                    </div>

                    <hr style="margin-bottom: 12px">

                    <h6 class="section-title">Obecná nastavení</h6>

                    <div style="margin-bottom: 8px;">
                        <div class="control">
                            <b-form-checkbox v-model="showDetailChartsForSources">
                                Zobrazovat grafy i pro jednotlivé modely
                            </b-form-checkbox>
                        </div>
                    </div>

                    <div class="control" style="margin-bottom: 12px; display: flex; align-items: center;">
                        <span style="white-space: nowrap; margin-right: 7px">Velikost grafů: </span>
                        <b-form-select v-model="detailChartHeight" size="sm" :options="chartHeightOptions"
                                       right variant="primary" class="my-select">

                        </b-form-select>
                    </div>
                </div>

                <div style="text-align: center; margin-bottom: 12px">
                    <b-button @click="clickOk" variant="primary" >
                        Nastavit
                    </b-button>
                </div>
            </div>
        </Transition>

    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BButton, BFormSelect, BFormCheckbox, BFormRadioGroup, BFormRadio } from "bootstrap-vue";
import SettingsIcon from "@/components/forecast/SettingsIcon.vue";
import {CHART_HEIGHT} from "@/store/modules/forecast-details-store";

export default {
    name: "DetailPreferencesSettingsPopup",
    computed: {
        ...mapGetters('forecastsStore', [
            'getDetailSourceMode', 'isDisplayedWholeCountry']),
        ...mapGetters('forecastDetailsStore', [
            'isShowDetailChartsForSources', 'getDetailChartHeight',
            'getPreferredDetailSource']),
    },
    mounted() {
        this.detailSourceMode = this.getDetailSourceMode; // TODO this causes the API to be called twice ...
        this.showDetailChartsForSources = this.isShowDetailChartsForSources;
        this.detailChartHeight = this.getDetailChartHeight;
        this.preferredDetailSource = this.getPreferredDetailSource;
        console.log("mounted DetailPreferencesSettingsPopup: ");
        console.log("mounted detailSourceMode = " + this.getDetailSourceMode);
        console.log("mounted showDetailChartsForSources = " + this.isShowDetailChartsForSources);
        console.log("mounted detailChartHeight = " + this.getDetailChartHeight);
        console.log("mounted preferredDetailSource = " + this.getPreferredDetailSource);
    },
    data() {
        return {
            popupDisplayed: false,
            detailSourceMode: undefined,
            showDetailChartsForSources: undefined,
            detailChartHeight: undefined,
            preferredDetailSource: undefined,
            shouldReloadForecasts: false,
            availableDetailSources: [
                { value: 'METEOSOURCE_API', text: 'Clear Outside' },
                { value: 'OPEN_METEO_ECMWF', text: 'ECMWF' },
                { value: 'OPEN_METEO_ICON', text: 'ICON D2' },
                { value: 'MET_NORWAY_API', text: 'MET Norway' },
                { value: 'METEOBLUE_WEB', text: 'Meteoblue' }
            ],
            chartHeightOptions: [
                { value: CHART_HEIGHT.SMALL.name, text: CHART_HEIGHT.SMALL.label },
                { value: CHART_HEIGHT.MEDIUM.name, text: CHART_HEIGHT.MEDIUM.label },
                { value: CHART_HEIGHT.LARGE.name, text: CHART_HEIGHT.LARGE.label },
            ]
        }
    },
    watch: {
    },
    methods: {
        ...mapActions('forecastsStore', ['changeDetailSourceMode', 'reloadForecasts']),
        ...mapActions('forecastDetailsStore', ['changeShowDetailChartsForSources',
            "changeDetailChartHeight", 'changePreferredDetailSource'
        ]),
        clickOk() {
            this.changeDetailSourceMode(this.detailSourceMode);
            this.changeShowDetailChartsForSources(this.showDetailChartsForSources)
            this.changeDetailChartHeight(this.detailChartHeight)
            this.changePreferredDetailSource(this.preferredDetailSource)
            this.togglePopup();
            if (this.shouldReloadForecasts && !this.isDisplayedWholeCountry) {
                this.reloadForecasts();
            }
            this.shouldReloadForecasts = false;
        },
        togglePopup() {
            this.popupDisplayed = !this.popupDisplayed;
            if (this.popupDisplayed) {
                this.$emit('childPopupOpened');
            } else {
                this.$emit('childPopupClosed');
            }
        },
        touchedSourceModeSettings() {
            console.log("touchedSourceModeSettings ...");
            this.shouldReloadForecasts = true;
        },
    },
    components: {
        SettingsIcon,
        BButton,
        BFormSelect,
        BFormCheckbox,
        BFormRadioGroup,
        BFormRadio
    }
}
</script>

<style scoped>

.control {
    text-align: left;
    margin-left: 20px
}

.sub-control {
    text-align: left;
    margin-left: 42px
}

.mode-option {
    margin-bottom: 6px; /* Adjust the value as needed */
}

.section-title {
    margin-bottom: 12px;
}

.my-select {
    width: 130px;
}

</style>
