<template>

    <div>
        <b-navbar class="fixed-top" toggleable="lg" type="dark" variant="primary">

            <b-navbar-brand to="/" style="display: flex; align-items: center">
                <img src="@/assets/site_logo/logo-cloud-telescope-v4.png" alt="Logo"
                     class="custom-logo">
                SkyAccuracy.cz
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item to="/predpovedi" class="item-padding">Předpovědi</b-nav-item>
                    <b-nav-item to="/spolehlivost" class="item-padding">Spolehlivost</b-nav-item>
                    <b-nav-item to="/projekt" class="item-padding">O projektu</b-nav-item>
                    <b-nav-item to="/aktuality" class="item-padding">Aktuality</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>

</template>


<script>
import {mapActions, mapGetters} from "vuex";
import {narrowViewPortMixin} from "@/components/reusables/narrow-view-port-mixin";
import {
    BNavbar,
    BNavbarNav,
    BNavbarBrand,
    BNavbarToggle,
    BNavItemDropdown,
    BNavItem,
    BCollapse,
    BDropdownItem
} from 'bootstrap-vue';
import {Utils} from "@/utils/Utils";

export default {
    name: 'NavBar',
    components: {
        // eslint-disable-next-line vue/no-unused-components
        BNavbar, BNavbarNav, BNavbarBrand, BNavbarToggle, BNavItemDropdown, BNavItem, BCollapse, BDropdownItem
    },
    mixins: [narrowViewPortMixin],
    computed: {
        Utils() {
            return Utils
        },
        ...mapGetters([]),
    },
    methods: {
        ...mapActions([]),
    },

}
</script>


<style>

.item-padding {
    padding-left: 10px;
}

.custom-logo {
    height: 35px; /* Adjust the height as needed */
    margin-right: 8px;
}

//overwrite navbar paddings to make space for a bigger logo
.navbar-brand {
    padding: 0 !important;
}

.navbar {
    padding: 0.4rem 1rem !important;
}

</style>


