<template>

    <div class="share-popup popup-text">
        <div class="popup-text small-warning-text">
            <p>
                <span style="font-weight: bold">Malá prosba:</span>
                Nesdílejte, prosím, web s lidmi mimo astro komunitu. Povolené denní počty dotazů na data předpovědí jsou značně limitovány
                a příliš mnoho uživatelů by znamenalo omezení služeb pro nás astronomy.
            </p>
            <p>
                Stejný důvod je i za omezenou platností odkazu. Návštěva předpovědi pro konkrétní lokalitu konzumuje limit dotazů na data.
            </p>
            <p>
                Děkujeme :)
            </p>
        </div>
        <hr>
        <div style="font-weight: 600; font-size: 17px; margin-left: 3px">Odkaz platný {{getForecastsLink.validity}}:</div>
        <div>
            <div style="margin-top: 5px; margin-bottom: 15px">
                <input id="linkInputId" type="text" class="form-control form-control-sm" v-bind:value="createForecastsLink()" autocomplete="off">
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <button v-if="!linkCopied" class="btn btn-primary" @click="onCopyLinkClick">
                    Zkopírovat
                </button>
                <div v-if="linkCopied">
                    <span class="material-icons notranslate" style="color: #44e334; font-size: 35px; vertical-align: middle;">done</span>
                    <span style="vertical-align: middle; margin-left: 3px">hotovo</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
    name: "SharePopup",
    computed: {
        ...mapGetters('forecastsStore', ['getForecastsLink']),
        currentUrl() {
            return window.location.origin + this.$route.path;
        }
    },
    watch: {
    },
    data() {
        return {
            linkCopied: false,
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['']),
        createForecastsLink() {
            const pagePath = "/predpovedi";
            const idx = this.currentUrl.search(pagePath); // if we already are on a calculation link we need to get its base
            let url = this.currentUrl;
            if (idx > -1) {
                url = this.currentUrl.substring(0, idx + pagePath.length);
            }
            return url + "/" + this.getForecastsLink.uuid
        },
        async onCopyLinkClick() {
            const linkInputElem = document.getElementById("linkInputId");
            if (!navigator.clipboard) {
                linkInputElem.select();
                linkInputElem.setSelectionRange(0, 99999)
                document.execCommand("copy");
            } else {
                let content = linkInputElem.value;
                console.log(content);
                await navigator.clipboard.writeText(content);
            }
            this.linkCopied = true;
        }
    },
    components: {
    }
}
</script>

<style scoped>

.share-popup {
    width: 300px;
    position: fixed;
    bottom: 85px;
    right: 20px;
    background-color: rgb(245, 245, 245);
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    z-index: 10000;
}


@media (max-width: 699px) {
    .share-popup {
        width: 280px;
    }
}

@media (min-width: 700px) {
    .share-popup {
        width: 350px;
    }
}

</style>
